import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import {Typography} from "@mui/material";

//  rightLink = {
//   fontSize: 16,
//   color: 'common.white',
//   ml: 3,
// };

function AppAppBar() {
  return (
    <div>
      <AppBar id="appbar" position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1 }} />
            {/*align="center"*/}
            <Typography variant="h6" color="inherit">
                {'Enclave Software'}
            </Typography>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {/*  <Link*/}
          {/*    color="inherit"*/}
          {/*    variant="h6"*/}
          {/*    underline="none"*/}
          {/*    href="/"*/}
          {/*    sx={rightLink}*/}
          {/*  >*/}
          {/*    {'link 1'}*/}
          {/*  </Link>*/}
          {/*  <Link*/}
          {/*    variant="h6"*/}
          {/*    underline="none"*/}
          {/*    href="/"*/}
          {/*    sx={{ ...rightLink, color: 'secondary.main' }}*/}
          {/*  >*/}
          {/*    {'link 2'}*/}
          {/*  </Link>*/}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
