import React from "react";

// eslint-disable-next-line
interface PriorityImgAttributes<T> extends React.ImgHTMLAttributes<any> {
    fetchpriority?: 'high' | 'low' | 'auto';
    alt: string;
}

export class PriorityImg extends React.Component<PriorityImgAttributes<any> & React.ImgHTMLAttributes<any>, {}> {
    render() {
        // linter complaining about alt prop, even we're forcing with PriorityImgAttributes
        // eslint-disable-next-line
        return <img {...this.props}/>;
    }
}