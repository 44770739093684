import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import {PriorityImg} from "../components/PriorityImg";
import React from "react";

// const backgroundImage = '/img/factory_back.jpg'
const backgroundImage = '/img/Northrop_F5E_Tiger_II.jpg'
// 'https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400';


export default function ProductHero() {

  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#434445', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <PriorityImg
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="planes on an assembly line"
        fetchpriority="high"
      />
      <Typography color="inherit" backgroundColor={'primary.dark'} align="center" variant="h2" marked="center" sx={{p:1.25}}>
        we defend american industries
      </Typography>
        <Typography color="inherit" backgroundColor={'primary.dark'} align="center" variant="h5" marked="center" sx={{p:1.25}}>
            cybersecurity and risk mitigation
        </Typography>
    </ProductHeroLayout>
  );
}
