import './App.css';
import {ThemeProvider} from "@mui/material/styles";
import theme from './theme';
import Index from "./Home";

function App() {
  return (
    <ThemeProvider theme={theme}>
        {/* only index for now */}
        <Index/>
    </ThemeProvider>
  );
}

export default App;
