const roughEmailRe = new RegExp(`[\\w\\.]+@[\\w\\.\\-]+`);
export function IsEmail(v: string): boolean {
    // validation.ts has a more elaborate check
    if (!v.match(roughEmailRe)) {
        return false
    }
    return true
}

export function email(value: string): string|null {
  return value && !IsEmail(value.trim()) ? 'Invalid email' : null;
}

function isDirty(value: string | number) {
  return value || value === 0;
}

export function required(
  requiredFields: readonly string[],
  values: Record<string, string>,
): Record<string, string> {
  return requiredFields.reduce(
    (fields, field) => ({
      ...fields,
      ...(isDirty(values[field]) ? undefined : { [field]: 'Required' }),
    }),
    {},
  );
}

