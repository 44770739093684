import * as React from 'react';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductCTA from './modules/views/ProductCTA';
import ProductFeatures from './modules/views/ProductFeatures';
import AppHeaderBar from './modules/views/AppAppBar';
import withRoot from './modules/withRoot';

function Index() {
  return (
    <React.Fragment>
      <AppHeaderBar />
      <ProductHero />
      <ProductFeatures />
      <ProductCTA />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
