import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';
import {
    alpha,
    FormControl,
    formLabelClasses,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {styled} from "@mui/material/styles";

import {inputBaseClasses} from "@mui/material";
import theme from "../../theme";
import {IsEmail} from "../form/validation";
import {GetAxiosInstance} from "../../httpClient";

// class is for controlling styled
const classes = {
    root: 'MuiInputBase-root',
};

// this is getting ignored
// const styles = ({ theme }: { theme: Theme }) => ({
//     [`& .${classes.formControl}`]: {
//         backgroundColor: '#00f',
//     },
//     [`.MuiInputBase-root`]: {
//         backgroundColor: '#00f',
//     },
//     [`& .${classes.root}`]: {
//         backgroundColor: '#00f',
//         color: theme.palette.common.white,
//     },
//     [`.${classes.root}`]: {
//         backgroundColor: '#00f',
//         color: theme.palette.common.white,
//     },
// });

const StyledFormControl = styled(FormControl)({
    [`& .${inputBaseClasses.root}`]: {
        backgroundColor: theme.palette.common.white,
        color: alpha(theme.palette.common.black, 0.40),
        border: 'none'
    },
    [`& .${formLabelClasses.root}`]: {
        // controls the color of the text for the "company size" box before selected
        // match the other input boxes
        color: alpha(theme.palette.common.black, 0.40)
    },
    // todo: border color
    // .css-dro62-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline
    [`& .${inputBaseClasses.disabled}`]: {
        backgroundColor: alpha(theme.palette.secondary.dark, 0.12),
    },
}) as typeof FormControl;

function ProductCTA() {

    const [open, setOpen] = React.useState(false);

    const [formDisabled, setFormDisabled] = React.useState(false);

    const [formPersonName, setFormPersonName] = React.useState('')
    const [formPersonNameError, setFormPersonNameError] = React.useState('')

    const [formEmail, setFormEmail] = React.useState('')
    const [formEmailError, setFormEmailError] = React.useState('')

    const [formCompanyName, setFormCompanyName] = React.useState('')
    const [formCompanyNameError, setFormCompanyNameError] = React.useState('')

    const [formCompanySize, setFormCompanySize] = React.useState('')

    const [formComment, setFormComment] = React.useState('')

    const [postError, setPostError] = React.useState('')

    // remove textfield on submit
    let submitText = formDisabled ? "Sent" : "Send"

    interface ContactData {
        personName: string;
        email: string;
        companyName: string;
        companySize: string;
        comment: string;
    }

    // HTMLDivElement instead of HTMLFormElement because we're using a <Box>
    const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
        console.debug(event)
        event.preventDefault();

        if (formPersonName.length < 2) {
            setFormPersonNameError('please enter your name')
            return
        }

        if (formCompanyName.length < 2) {
            setFormCompanyNameError('please enter your company name')
            return
        }

        // submit with cookievalues
        if (!IsEmail(formEmail)) {
            console.error('invalid email format')
            setFormEmailError('invalid email format')
            setFormDisabled(false)
            return
        }

        let data: ContactData = {
            "personName": formPersonName,
            "email": formEmail,
            "companyName": formCompanyName,
            "companySize": formCompanySize,
            "comment": formComment
        }
        // get target values for each state
        setFormDisabled(true)
        let httpClient = GetAxiosInstance()
        httpClient.post("/contact", data, {withCredentials: true})
            .then(() => {
                setPostError('')
                setOpen(true);
                console.debug("sent contact")
            }).catch(err => {
                console.error(err)
                try {
                    let errResp = err.response.data;
                    if (errResp.reason === "reload required") {
                        setPostError('please refresh the page and try sending again');
                        setFormDisabled(false);
                        return
                    }
                } catch(error) {
                    console.error(err.response.data);
                }
                setPostError('please try sending again');
                setFormDisabled(false);
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container className={"ContactComponent"} component="section" sx={{pt: 10, mb: 10, display: 'flex'}}>
            <Grid container>
                <Grid item xs={12} md={6} sx={{zIndex: 1}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            // bgcolor: 'warning.main',
                            bgcolor: theme.palette.secondary.main,
                            py: 8,
                            px: 3,
                        }}
                    >
                        <Box component="form" onSubmit={handleSubmit} sx={{maxWidth: 400}}>
                            <Typography variant="h2" component="h2" gutterBottom>
                                Contact us
                            </Typography>
                            <Typography variant="h5">
                            </Typography>

                            <TextField
                                noBorder
                                disabled={formDisabled}
                                inputProps={{maxLength: 100}}
                                placeholder="Your name"
                                variant="standard"
                                sx={{width: '100%', mt: 2, mb: .5}}
                                onChange={(e) => {
                                    setFormPersonNameError('')
                                    setFormPersonName(e.target.value.trim() as string)
                                }}
                                helperText={formPersonNameError}
                            />

                            <TextField
                                noBorder
                                disabled={formDisabled}
                                inputProps={{maxLength: 100}}
                                placeholder="Your email"
                                variant="standard"
                                sx={{width: '100%', my: .5}}
                                onChange={(e) => {
                                    setFormEmailError('')
                                    setFormEmail(e.target.value.trim() as string)
                                }}
                                helperText={formEmailError}
                            />

                            <TextField
                                noBorder
                                disabled={formDisabled}
                                inputProps={{maxLength: 100}}
                                placeholder="Company name"
                                variant="standard"
                                sx={{width: '100%', my: .5}}
                                onChange={(e) => {
                                    setFormCompanyNameError('')
                                    setFormCompanyName(e.target.value.trim() as string)
                                }}
                                helperText={formCompanyNameError}
                            />

                            <StyledFormControl fullWidth classes={{root: classes.root}}>
                                <InputLabel id="company-size">Company size</InputLabel>
                                <Select
                                    disabled={formDisabled}
                                    labelId="company-size"
                                    id="company-size"
                                    value={formCompanySize}
                                    label="Company size"
                                    MenuProps={{
                                        sx: {
                                            // applies to each menuitem, overrides default
                                            "& .MuiMenu-paper": {
                                                backgroundColor: theme.palette.common.white,
                                            },
                                            my: .5
                                        }
                                    }}
                                    onChange={(e) => {
                                        setFormCompanySize(e.target.value as string)
                                    }}
                                >
                                    <MenuItem value={'<10'}>{'< 10'}</MenuItem>
                                    <MenuItem value={'10-49'}>{'10-49'}</MenuItem>
                                    <MenuItem value={'50-249'}>{'50-249'}</MenuItem>
                                    <MenuItem value={'250-999'}>{'250-999'}</MenuItem>
                                    <MenuItem value={'1000+'}>{'1000+'}</MenuItem>
                                </Select>
                            </StyledFormControl>

                            <TextField
                                noBorder
                                multiline
                                rows={2}
                                disabled={formDisabled}
                                inputProps={{maxLength: 100}}
                                placeholder="Comments"
                                variant="standard"
                                sx={{width: '100%', my: .5}}
                                onChange={(e) => {
                                    setFormComment(e.target.value.trim() as string)
                                }}
                            />

                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                sx={{width: '100%'}}
                            >
                                {submitText}
                            </Button>
                            <Typography
                                sx={{mt: 1, textAlign: 'center'}}
                                variant='subtitle1'
                                color={theme.palette.error.dark}
                                backgroundColor={theme.palette.primary.dark}
                            >
                                {postError}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{display: {md: 'block', xs: 'none'}, position: 'relative'}}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: -67,
                            left: -67,
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            background: 'url(/img/productCTAImageDots.png)',
                        }}
                    />
                    <Box
                        component="img"
                        src='/img/industrial_steel_750.jpg'
                        alt="image decoration for email updates"
                        sx={{
                            position: 'absolute',
                            top: -28,
                            left: -28,
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            maxWidth: 600,
                        }}
                    />
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                closeFunc={handleClose}
                message="Thank you.  We'll be in contact."
            />
        </Container>
    );
}

// couldn't get this targeted
// export default styled(ProductCTA)(styles)
export default ProductCTA;
