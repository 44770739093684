import Typography from '../components/Typography';
import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import theme from "../../theme";
import { Icon } from '@iconify/react';
import {Theme} from "@mui/material/styles";
import {SxProps} from "@mui/system";

const item: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 2,
    textAlign: 'center'
};

export default function ProductFeatures() {
    return (
        <Box
            component={"section"}
            sx={{ display: 'flex', overflow: 'hidden', bgcolor: theme.palette.common.white }}
        >
            <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
                <Grid container spacing={5}>

                    {/* compliance */}
                    <Grid item xs={12} md={6}>
                        <Box sx={item}>
                            <Box>
                                <Icon icon="octicon:checklist-16" width={50} height={50} />
                            </Box>
                            <Typography variant="h6" sx={{ my: 5 }}>
                                {'Compliance and Risk Mitigation'}
                            </Typography>
                            <Typography variant="h5">
                                {'Our comprehensive platform helps you get and stay compliant, while addressing '}
                                {'underlying threats.'}
                            </Typography>
                        </Box>
                    </Grid>

                    {/* security features*/}
                    <Grid item xs={12} md={6}>
                        <Box sx={item}>
                            <Box>
                                <Icon icon="octicon:repo-locked-24" width={50} height={50} />
                            </Box>
                            <Typography variant="h6" sx={{ my: 5 }}>
                                {'Secure by design'}
                            </Typography>
                            <Typography variant="h5">
                                {'We use AES 256-bit encryption and employ multiple strategies to secure your data.'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
