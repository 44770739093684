import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Flag from 'react-world-flags';

function Copyright() {
  return (

      <Grid container alignItems="center" spacing={0}>

        <Grid item xs={2}>
          <img
              src="/img/enclave_logo.svg"
              alt="enclave logo"
              width="25"
              height="25"
          />
        </Grid>
        <Grid item xs={10}>
          <Typography
              component="footer"
              variant="caption"
              color={'primary.light'}
              sx={{ display: 'flex', py:0 }}
          >
            {'© Enclave Software Inc.'}{' '}{new Date().getFullYear()}
          </Typography>
          <Typography
              component="footer"
              variant="caption"
              color={'primary.light'}
              sx={{ display: 'flex', py:0 }}
          >
            {' '}{'Exeter, New Hampshire, USA'}
          </Typography>
        </Grid>


      </Grid>

  );
}

// const iconStyle = {
//   width: 48,
//   height: 48,
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   backgroundColor: 'warning.main',
//   mr: 1,
//   '&:hover': {
//     bgcolor: 'warning.dark',
//   },
// };

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'primary.dark' }}
    >
      <Container sx={{ mt:4, mb: 2, display: 'flex' }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={3}>
            <Copyright />
          </Grid>
          <Grid item xs={6} sm={8} md={4}></Grid>
            <Grid item>
              <Typography variant="caption" color={'primary.light'} >
                {/* underlying object is an image, all but code and fallback are passed through */}
                <Flag code="USA" height={12} alt="USA flag"/>
                {' Proudly made in the US'}
              </Typography>
            </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
